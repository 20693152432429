/*
 * SPDX-FileCopyrightText: 2020-2023 Helmholtz-Zentrum hereon GmbH
 *
 * SPDX-License-Identifier: EUPL-1.2
 */

/** Todo list **/

.ck .todo-list input {
    padding: 0;
}

.ck .todo-list__checkmark:after {
    height: 6px !important;
}

.ck .todo-list__checkmark {
    padding: 0;
}

.ck.ck-content.ck-editor__editable {
    min-height: 300px;
}

.ck.ck-balloon-panel.ck-balloon-panel_visible {
    z-index: 2002;
    max-width: 75%;
}

.ck.ck-balloon-panel.ck-balloon-panel_visible.ck-toolbar-container {
    z-index: 2001;
}

.community-editor-inline.ck.ck-content.ck-editor__editable {
    min-height: 1em;
}

.community-editor-balloon.ck.ck-content.ck-editor__editable {
    min-height: 1em;
}

.ck.ck-list__item .ck-button.ck-on {
    background: transparent;
    color: #3B8BEB;
    background: var(--ck-color-list-button-hover-background);
}

.ck.ck-list .ck-list__item {
    border: 1px solid #dee2e6;
}

.ck.ck-list__item .ck-button.ck-on:hover:not(.ck-disabled) {
    background: var(--ck-color-list-button-hover-background);
}

.ck h2 {
    color: inherit;
    background: inherit;
}

form .aligned .ck ul li {
    list-style: inherit;
}

form .aligned .ck ul {
    margin-left: 1.5em;
    padding-left: inherit;
}

.ck-content .mention {
    background: transparent;
}
